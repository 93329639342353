// import CSS
import styles from '../css/app.pcss';

// app main
const main = async () => {

    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

    // Create our vue instance
    const vm = new Vue({
        el: '#component-container',

        components: {
            'confetti': () => import(/* webpackChunkName: "confetti" */ '../vue/Confetti.vue')
        },

        data: function() {
            return {

            };
        },

        methods: {
            handleScroll (event) {
                if( window.pageYOffset >= 120 ) {
                    document.body.classList.add("scrolled");
                }

                if( window.pageYOffset == 0 ) {
                    document.body.classList.remove("scrolled");
                }

                const target = document.querySelectorAll('.parallax');
                let windowHeight = (window.innerHeight || document.documentElement.clientHeight);

                let index = 0, length = target.length;
                for (index; index < length; index++) {
                    if( this.isElementInViewport(target[index]) ) {

                        if( !target[index].getAttribute('data-offset-top') ) {
                            target[index].setAttribute('data-offset-top', target[index].getBoundingClientRect().top + window.scrollY )
                        }

                        let scrollY = ( (window.innerHeight || document.documentElement.clientHeight) + window.pageYOffset ) - target[index].getAttribute('data-offset-top');
                        if( target[index].getAttribute('data-offset-top') < (window.innerHeight || document.documentElement.clientHeight) ) {
                            scrollY = window.scrollY
                        }                       

                        if( ! isNaN(scrollY) ) {
                            let pos = scrollY * target[index].dataset.rate;
                                pos = target[index].getAttribute('data-rate-reverse') ? pos * -1 : pos;

                            if(target[index].dataset.direction === 'vertical') {
                                target[index].style.transform = 'translate3d(0px,'+pos+'px, 0px)';
                            } else {
                                let posX = scrollY * target[index].dataset.ratex;
                                    posX = target[index].getAttribute('data-rate-reverse') ? posX * -1 : posX;
                                let posY = scrollY * target[index].dataset.ratey;
                                    posY = target[index].getAttribute('data-rate-reverse') ? posY * -1 : posY;
    
                                if( target[index].getAttribute('data-rate-reverse') ) {
                                    let offsetX = ( windowHeight * target[index].dataset.ratex ) + target[index].offsetWidth;
                                    posX = offsetX + Math.abs( posX );
                                    posX = posX >= 0 ? 0 : posX;
                                }
    
                                target[index].style.transform = 'translate3d('+posX+'px, '+posY+'px, 0px)';
                            }
                        }
                    }
                }
            },

            handleUnload( event ) {
                document.querySelector('div.loader').classList.add('unload');
            },

            isElementInViewport(el, percentVisible=1) {
                let
                  rect = el.getBoundingClientRect(),
                  windowHeight = (window.innerHeight || document.documentElement.clientHeight);
              
                return !(
                  Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100)) < percentVisible ||
                  Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
                )
            },
        },

        mounted() {

        },

        created () {
            document.body.classList.add("js-loaded");
            document.querySelector('div.loader').classList.add('active');
            window.addEventListener('scroll', this.handleScroll);
            window.addEventListener('beforeunload', this.handleUnload);
        },
       
        destroyed () {
            window.removeEventListener('scroll', this.handleScroll);
            window.removeEventListener('beforeunload', this.handleUnload);
        },
    })

    return vm;
};

// Execute async function
main().then( (vm) => {
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
